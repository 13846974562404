import {useEffect, useState} from 'react';
import { useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { setUser, setUserType, setSnackData, setLoading, setUserSearch, setUserFilterGroup, setUserFilterRole, setUserPaginationData, setUserPerPageData} from '../../redux/action/userAction';
import { setGroups } from '../../redux/action/groupAction';
import { validatePermission } from "../../common/functions/permissionFunctions";
import fileUpload from "../../assets/svg/file-upload-solid.svg";
import * as API from '../../common/api/index.js';
import URL from "../../common/api/constantURL";
import Table from '../../common/component/Table'
import Confirmation from "../../common/component/Confirmation"
import BatchImprtPopUp from '../../common/component/BatchImprtPopUp';
import BreadCrumbIcon from '../../assets/images/breadcrum.png';
import BreadCrumb from '../../common/component/BreadCrumb';
import moment from "moment-timezone";
import '../Style/style.css';

function Users(){
	const users = useSelector(state => state.userReducer.users)
	const searchData = useSelector(state=>state.userReducer.userSearchData)
	const filterRoleData = useSelector(state=>state.userReducer.userFilterRoleData)
	const filterGroupData = useSelector(state=>state.userReducer.userFilterGroupData)
	const userPaginationData = useSelector(state=>state.userReducer.userPaginationData)
	const userPerPageData = useSelector(state=>state.userReducer.userperPageData)
	const groups = useSelector(state => state.groupReducer.groups)
	const userTypes = useSelector(state => state.userReducer.userTypes)
	const userPermissions = useSelector(state => state.userReducer.userPermissions)
	const deploymentConfiguration = useSelector(state => state.deployementReducer.deployementConfiguration)
	const [isDeviceBasedAlert,setIsDeviceBasedAlert] = useState(0)
	const [search,setSearch]=useState(searchData);
	const [role,setRole]=useState(filterRoleData);
	const [grade,setGrade]=useState(filterGroupData);
	const [confirmation, setConfirmation] = useState(false);
	const [confirmationData, setConfirmationData] = useState(false);
	const [batchImportPopUp, setBatchImportPopUp] = useState(false)
	const navigate = useNavigate()
	const dispatch = useDispatch();
	const BreadCrumbArr = [
		{link:"/home/dashboard",display:"Dashboard",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon' />,type:'img'},
		{link:"/home/users",display:"Users and Groups",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon' />,type:'img'},
		{link:"/home/users",display:"Users",type:"react"},
	];

	const sendLink = async (row) => {
		if(!row.userName || !(row.userName.length>0)){
			let snackData = {
				showSnack: true,
				snackMessage: "Email is missing.",
				snackVariant: "error"
			}
			setTimeout(()=>{
				return dispatch(setSnackData(snackData))
			}, 50)

		}else{
			let data = {
				"userName": row.userName,
			}
			let response = await API.postAPI(URL.forgotPassword, data)
			if (response.fetchStatus === "success") {
				if (response.result.status === "success") {
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "success"
					}
					dispatch(setSnackData(snackData))
				} else {
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "error"
					}
					dispatch(setSnackData(snackData))
				}
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: "Server down.Try again.",
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		}
	}
	const navigateRole = (row,index)=>{
		return navigate("/home/roles/" + row.roleIds[index])
	}


	useEffect(() => {
		dispatch(setUserFilterGroup(""))
		dispatch(setUserFilterRole(""))
		dispatch(setUserSearch(""))
		dispatch(setUserPaginationData(0))
		dispatch(setUserPerPageData(10))
		dispatch(setLoading(true))
		fetchUserType();
	}, []);

	useEffect(() => {
		if(deploymentConfiguration.Deployment){
			setIsDeviceBasedAlert(deploymentConfiguration.Deployment.isDeviceBasedAlert ? 1 : 0)
		}
	}, [deploymentConfiguration]);
	
	const getRoleName= (roles,rolesIds) =>{
        let rolesList =[]
        rolesIds.forEach(function(role) {
            roles.forEach(function(roleId){
                if(role === roleId.id){
                    rolesList.push(roleId.roleName)
                }
            })
        });
        return rolesList
    }

	const editUserDetails =(data, page, perPage)=>{
		dispatch(setUserFilterGroup(grade))
		dispatch(setUserFilterRole(role))
		dispatch(setUserSearch(search))
		dispatch(setUserPaginationData(page))
		dispatch(setUserPerPageData(perPage))
		navigate(`/home/users/edit-user/`+data.id)
	}

	const handleSearch=(e)=>{
		setSearch(e.target.value)

	}

	const formatTableData = (userListTypes) => {
		let userList = users
		let tempData = []
		userList && userList.map((user) => {
			let tempObj = {}
			tempObj = user
			// this field helps to avoid deleting the loggedin user by their own
			tempObj["loggedInUser"] = parseInt(localStorage.getItem("loginUserId")) === user.id;
			tempObj["role"] = user.roleIds ? getRoleName(userListTypes, user.roleIds) : '-';
			if(deploymentConfiguration.Deployment && deploymentConfiguration.Deployment.isDeviceBasedAlert){
				tempObj["email_phone"] = (user.email || "-") + " \n " + (user.phoneNumber || "")
			}else{
				tempObj["email_phone"] = (user.userName || "-") + " \n " + (user.phoneNumber || "")
			}
			tempObj["password_reset"] = "Send Link"
			tempData.push(tempObj)
		})
		return tempData
		// setUserDetails(tempData);
	}

	const fetchGroup = async () => {
		let url = URL.groupsByFacility + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.groups
		}
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let groupList = response.result.result
				for (let i = 0; i < groupList.length; i++) {
					groupList[i].userCount = groupList[i].userIds.length
					groupList[i].createdOn = moment(groupList[i].createdAt).format("LL")
					if (groupList[i].isEventGroup) {
						groupList.splice(i,1)
						i--
					}
				}
		    	dispatch(setGroups(groupList))
			} else {
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))

			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack:true,
				snackMessage:"Server down.Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}

	}
	const fetchUserType = async () => {
		let response = await API.getAPI(URL.rolesByFacility + "?facilityId=" + localStorage.getItem("facilityId"))
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let userListTypes = response.result.result
				dispatch(setUserType(userListTypes))	
				fetchUser(userListTypes);	
			} else {
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
				fetchUser([])
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack:true,
				snackMessage:"Server down.Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
			fetchUser([]);
		}

	}

	const fetchUser = async (userListTypes) => {
		let url = URL.usersByFacility + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.users
		}
		let response = await API.getAPI(url)
		fetchGroup()
		if(response.fetchStatus === "success"){
			let userList = response.result.result
			if(response.result.status === "success"){
				dispatch(setUser(userList));
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else {
			let snackData = {
				showSnack:true,
				snackMessage:"Server down.Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const batchImportHandler=async(fileData, sendMailToAll)=>{
		var form_data = new FormData();
		dispatch(setLoading(true))
		if (fileData.current.files.length > 0) {
			form_data.append("dataFile",fileData.current.files[0]);
		}
		if(sendMailToAll === true){
			form_data.append("sendMailToAll",1);
		}else{
			form_data.append("sendMailToAll",0);
		}
		form_data.append("facilityId", localStorage.getItem("facilityId"));
		form_data.append("isDeviceBasedAlert", isDeviceBasedAlert);
		let response = await API.postWithFileAPI(URL.batchImportUsers, form_data);
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"success"
				}
				dispatch(setSnackData(snackData))
				setBatchImportPopUp(false)
				fetchUser()
			}else{
				fileData.current.value = ""
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			fileData.current.value = ""
			let snackData = {
				showSnack:true,
				snackMessage:"Unable to upload. Try Again!",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const deleteUser = async () => {
		dispatch(setLoading(true))
		let data = {
			"id": confirmationData.id,
			"facilityId": localStorage.getItem("facilityId"),
		}
		let response = await API.deleteAPI(URL.users, data)
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				navigate("/home/users")
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"success"
				}
				setConfirmation(false)
				fetchUserType();
				dispatch(setSnackData(snackData))

			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Unable to delete. Try Again!",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const showDeleteAction = (data) => {
		setConfirmation(true)
		setConfirmationData(data)
	}
	const filter = (values) =>{
		let tempValues = [];
		if(role && role.length > 0){
			values.map((value)=>{
				if(value.roleIds && value.roleIds.includes(parseInt(role)) )
					tempValues.push(value)
			})
		}else{
			tempValues = values
		}
		let updatedGroupValues = [];
		if(grade && grade.length > 0){
			if (grade === "No Group Assigned") {
				tempValues.map((value)=>{
					if (value.groupIds?.length === 0) {
						updatedGroupValues.push(value)
					}
				})
			} else {
				tempValues.map((value)=>{
					(value.groupIds || []).map((grpId,index)=>{
						if(grpId == grade){
							updatedGroupValues.push(value)
						}
					})
				})
			}
		
		}else{
			updatedGroupValues = tempValues
		}
		let updatedValues = []
		if(search && search.length > 0){
			updatedGroupValues.map((value)=>{
				let fullname = (value.firstName||"") + " " + (value.lastName||"")
				if(
					(value.firstName||"").toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
					(value.lastName||"").toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
					(value.userName||"").toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
					(fullname).toLocaleLowerCase().includes(search.toLocaleLowerCase())
				){
					updatedValues.push(value)
				}
			})
		}else{
			updatedValues = updatedGroupValues
		}

		if (!role && !search && !grade){
			return values
		}
		return updatedValues
	}

	let userData = formatTableData(userTypes);
	let tableHeaders = [
		{ id: 'profilePicture', label: 'Profile', type: 'image', width: 60, responsive: true, dataTestid: "profile-picture"},
		{ id: 'userName', label: 'User ID', width: 150, dataTestid: "user-username"},
		{ id: 'firstName', label: 'First Name', width: 100, responsive: true, sortable: true , dataTestid: "user-first-name"},
		{ id: 'lastName', label: "Last Name", width: 100, responsive: true, sortable: true , dataTestid: "user-last-name"},
		{ id: "role", label: "Role", type: 'multiple_data_Link', dataTestid: "user-role-name", path: '#', width: 70, sortable: true,responsive: true, clickFunc: navigateRole },
		{ id: 'passwordSet', label: 'Password Set', type: 'checkbox_icon', width: 70, /*justifyContent:"center"*/},
		{ id: 'password_reset', label: 'Password Reset', type: 'linkclick', path: '#', width: 110, clickFunc: sendLink,dataTestid: "send-link-data" },
		{ id: 'email_phone', label: 'Email & Phone', dataTestid: "user-email-id", width: 220 },
		{ id: 'manage', label: 'MANAGE', type: 'action', path: '2', width: 70, responsive: true }
	]
	if(deploymentConfiguration.Deployment && deploymentConfiguration.Deployment.isDeviceBasedAlert){
		tableHeaders = tableHeaders.filter(function (obj) {
			return obj.id !== "password_reset" && obj.id !== "passwordSet"
		});
	}else{
		tableHeaders = tableHeaders.filter(function (obj) {
			return obj.id !== "userName";

		});
	}
	if ((!validatePermission(userPermissions, "EDIT_USERS")) && (!validatePermission(userPermissions, "DELETE_USERS"))) {
		tableHeaders = tableHeaders.filter(function (obj) {
			return obj.id !== "manage";
		});
	}
	if (!validatePermission(userPermissions, "EDIT_ROLES")) {
		for (let index = 0; index < tableHeaders.length; index++) {
			const tableHeader = tableHeaders[index];
			if (tableHeader.id === "role") {
				tableHeaders[index].type = "multipleText"
			}
		}
	}
	return (
		<div className="user-page" id="wrapper">
				<div>
					<BreadCrumb  crumbs={BreadCrumbArr} dataTestid="users-breadcrum" ></BreadCrumb>
					<div className="top-content">
						<div className="list-page-title-div">
							<p data-testid="user-management-id" className='list-page-title'> User Management Options</p>
						</div>
						{validatePermission(userPermissions, "ADD_USERS") ?
							<div className="button_parent_div">
								<button className="common-button" id="single-user-btn-id" data-testid="single-user-btn-id" onClick={(event) => {
									event.preventDefault();
									dispatch(setUserSearch(search));
									dispatch(setUserFilterGroup(grade));
									dispatch(setUserFilterRole(role));
									navigate("/home/users/add-user");
								}}>
									Add Single User
								</button>
								<button className="import-button" data-testid="batch-import-id" id="batch-import-id" onClick={() => { setBatchImportPopUp(true) }}>
									<span className='user_batch_import'>Batch Import</span>
									<img src={fileUpload} alt="file-upload" className='import_icon'/>
								</button>
							</div>
						:null}
						<div className="columns" data-testid="user-selection-top-ctr">
							<div className="col col-12 bold" >
								<p data-testid="user-selection-title-id">User Selection Option</p>
							</div>
							<div className="col col-12">
								<p data-testid="user-selection-id" className='user-selection-id'>Select a Role, Group or Search for a User</p>
							</div>
						</div>
						<div className="columns user-filter-column">
							<div className="col col-11" data-testid="user-role-ctr">
								<label htmlFor='role'>Role</label>
								<select className='user-page-select' data-testid="users-role-filter-box" id="users-role-filter-box" value={role} onChange={(e)=>{setRole(e.target.value)}}>
									<option value=''>All</option>
									{userTypes.map((type, index)=>
										<option value={type.id} data-testid="role-filter-data" id="role-filter-data" key={index} >{type.roleName}</option>
									)}
								</select>
							</div>
							<div className="col col-11" data-testid="users-group-filter-ctr">
								<label htmlFor='group' >Group</label>
								<select className='user-page-select' data-testid="users-group-filter-box" id="users-group-filter-box" value={grade} onChange={(e)=>setGrade(e.target.value)}>
									<option value=''>All</option>
									<option data-testid="noGroupAssigned" value={"No Group Assigned"}>No Group Assigned</option>
									{groups.map((group, index) =>
										<option value={group.id} data-testid="group-filter-data" id="group-filter-data" key={index}>{group.groupName}</option>
									)}
								</select>
							</div>
							<div className="col col-33 search user-page-search-container">
								<input type="text" value={search} data-testid="users-search-box" id="users-search-box" className="usersSearch" placeholder="Search" onChange={handleSearch}></input>
							</div>
							<div style={{ display: "flex" }} className="reclear user-reset-btn-ctr">
								<div data-testid="users-reset-btn" id="users-reset-btn"  className='pointer' onClick={()=>{setRole('');setGrade("");setSearch('')}} >Reset</div>
							</div>
						</div>
					</div>
					<div className="bottom-content ">
						<div className="col col-100">
							<Table
								headers={tableHeaders}
								tableTitle={"User Details"}
								data={filter(userData)}
								editAction={validatePermission(userPermissions, "EDIT_USERS") ? (data,page,perPage) => { editUserDetails(data,page, perPage) } : false}
								deleteAction={validatePermission(userPermissions, "DELETE_USERS") ? (data) => showDeleteAction(data): false}
								defaultOrderBy={"firstName"}
								defaultSortOrder={"asc"}
								disableDeleteForRow={true}
								paginationData={userPaginationData}
								perPageData = {userPerPageData}

							/>
						</div>
					</div>
					{confirmation ?
						<Confirmation deleteMessage ={"Are you sure you want to delete user "+confirmationData.firstName+" "+ confirmationData.lastName+"?"} successFunc={() => deleteUser()} cancelFunc={() => setConfirmation(false)} />
					: ""}
					{
						batchImportPopUp && <BatchImprtPopUp batchImportTitle={"Users"} isSendLinkProp={!deploymentConfiguration.Deployment.isDeviceBasedAlert} onClose={()=>{setBatchImportPopUp(false)}} csvDownloadSampleLink={URL.sampleFileDownloadUsers+'?type=csv&isDeviceBasedAlert='+isDeviceBasedAlert} xlsxDownloadSample={URL.sampleFileDownloadUsers+'?type=xlsx&isDeviceBasedAlert='+isDeviceBasedAlert} fileData={(fileData, sendMailToAll)=>{batchImportHandler(fileData, sendMailToAll)}}/>
					}
				</div>
		
		</div>);
}
export default Users;
