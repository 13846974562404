import { useState } from 'react';
import Table from '../../../common/component/Table';
import BreadCrumbIcon from '../../../assets/images/breadcrum.png';
import BreadCrumb from '../../../common/component/BreadCrumb';
import { IoArrowBackOutline } from "react-icons/io5"
import '../../Style/style.css';
import './Classes.css';

function ViewClass(props){
    const propsData = props.data
    const studentsList = propsData.studentsList.map((data)=>{return {"studentName" : data}})
    const [search,setSearch]=useState('');
	const BreadCrumbArr = [
		{link:"/home/dashboard",display:"Dashboard",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon' />,type:'img'},
		{link:"/home/users",display:"Users and Groups",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon' />,type:'img'},
		{link:"/home/classes",display:"Classes",type:"react"},
	];

	let tableHeaders = [
		{ id: 'studentName', label: 'Student Name', width: 100, responsive: true, sortable: true, dataTestid: "view-student-name"},
	]

    const filter = (values) =>{
		let tempValues = [];

		if(search && search.length > 0){
			values.forEach((value)=>{
				if((value.studentName||"").toLocaleLowerCase().includes(search.toLocaleLowerCase())){
					tempValues.push(value)
				}
			})
		}else{
			tempValues = values
		}

		if (!search){
			return values
		}
		return tempValues
	}

	return (
		<div> 
			<div>
				<BreadCrumb  crumbs={BreadCrumbArr} dataTestid="classes-breadcrum" ></BreadCrumb>
				<div className="list-page-title-div row classes-students-list-title">
					<p data-testid="view-class-name" title={propsData.name} className='list-page-title className-title'>Class Name: {propsData.name}</p>
					<p data-testid="view-advisor-name" title={propsData.advisorName.length > 0 ? propsData.advisorName.join(", ") : "-"}className='list-page-title'>Teacher: {propsData.advisorName.length > 0 ? propsData.advisorName.join(", ") : "-"}</p>
				</div>

				<div className='row button-arrange'>
					<div className='classes-search-wrap'>
						<div className="col col-33 margin-left-1-percent search">
							<input type="text" value={search} className="group-search" placeholder="Search" id="group-search-box" data-testid="view-class-search-box" onChange={(e)=>setSearch(e.target.value)}></input>
						</div>
						<div style={{ display: "flex" }} className="reclear group-reset-btn">
							<div data-testid="users-reset-btn" id="users-reset-btn"  className='pointer' onClick={()=>{setSearch('')}} >Reset</div>
						</div>
					</div>
					<span className="back-to-classes">
						<button onClick={() => props.onClose()} data-testid="back-to-classes" id="back-to-mustering-page"> <IoArrowBackOutline />Back to Classes</button>
					</span>
				</div>
			</div> 

			<Table
				data={filter(studentsList)}
				headers={tableHeaders}
				tableTitle={"Total Students"}
				defaultOrderBy={"studentName"}
				defaultSortOrder={"asc"}
			/>
		</div>
	)
}
export default ViewClass;
