import { React, useEffect, useState, useRef } from 'react';
import { NavLink, useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {useLocation} from "react-router-dom"
import '../style/SideBar.css';
import { SidebarData } from './SidebarData';
import DownArrow from '../../assets/svg/cadmin-icons_downarrow.svg';
import { BiMenu } from "react-icons/bi"
import { MdSettingsSuggest } from "react-icons/md"
import SSlogo from '../../assets/images/Status_Solutions_Logo-Header.svg'
import { validateSidebar } from "../functions/permissionFunctions";
import { setFacilitySelectionFlag } from "../../redux/action/accountAction";
import { setLoading } from '../../redux/action/userAction';
import { setDeloyementConfig } from '../../redux/action/deployementAction'; 
import * as API from '../../common/api/index.js';
import URL from "../../common/api/constantURL";
function Nav(props){
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const facilities = useSelector(state => state.accountReducer.facilities)
	const userPermissions = useSelector(state => state.userReducer.userPermissions);
	const facilitySelectionFlag = useSelector(state => state.accountReducer.facilitySelectionFlag)
	const deploymentConfiguration = useSelector(state => state.deployementReducer.deployementConfiguration)
	const [toggleMenu, setToggleMenu] = useState(false);
	const { logo, firstName, lastName, logged_school } = props;
	const [search, setSearch] = useState("")
	const [filteredMenu, setFilteredMenu] = useState([])
	const [openmenu, setOpenMenu] = useState("")
	const [activeMenu, setActiveMenu] = useState("")
	const clickIdentifyRef = useRef()
	const [subMenu, setSubMenu] = useState("")

	const updatePath = () =>{
		let path = window.location.pathname;
		setOpenMenu(0); 
		setActiveMenu(0);
		if (path.includes("users") || path.includes("groups") || path.includes("roles") || path.includes("devices") || path.includes("classes")) {
			setOpenMenu(2)
			setActiveMenu(2)
		}
		if (path.includes("application")) {
			setOpenMenu(4)
			setActiveMenu(4)
		}
		if (path.includes("configurations")) {
			setOpenMenu(4)
			setSubMenu(7)
			setActiveMenu(4)
		}
		if (path.includes("system")) {
			setOpenMenu(3)
			setActiveMenu(3)
		}
		if (path.includes("reports")) {
			setOpenMenu(5)
			setActiveMenu(5)
		}
		if (path.includes("support")) {
			setOpenMenu(6)
			setActiveMenu(6)
		}
	}
	useEffect(() => {
		updatePath()
		if(localStorage.getItem("facilityId")){
			fetchDeployementConfiguration()
		}
	},[]);

	const fetchDeployementConfiguration = async () => {
		let url = URL.alertSetUp + "?facilityId=" + localStorage.getItem("facilityId") + "&type=1";
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
                let data = response.result.result
                dispatch(setDeloyementConfig(data))
		}
	}}

	useEffect(() => {
		
		let matchingMenu = [];
		for (let index = 0; index < SidebarData.length; index++) {
			let val = SidebarData[index];
			if (val.subNav) {
				for (let innerIndex = 0; innerIndex < val.subNav.length; innerIndex++) {
					let submenu = val.subNav[innerIndex]
					if (submenu.subNav) {
						for (let innerIndex = 0; innerIndex < submenu.subNav.length; innerIndex++) {
							let innerSubMenu = submenu.subNav[innerIndex]
							if (innerSubMenu.sidebar && innerSubMenu.title.toLocaleLowerCase().includes(search.toLocaleLowerCase()) && innerSubMenu.roles.includes(parseInt(localStorage.getItem("functionalRoleId"))) && validateSidebar(userPermissions, innerSubMenu.refName)) {
								matchingMenu.push(<NavLink key={matchingMenu.length} onClick={() => {setOpenMenu(openmenu === val.id ? 0 : val.id);setSearch(""); toggleNav();}} className="search-result" to={innerSubMenu.path}>{innerSubMenu.title}</NavLink>)
							}
						}
					}
					else if(submenu.sidebar && submenu.title.toLocaleLowerCase().includes(search.toLocaleLowerCase()) && submenu.roles.includes(parseInt(localStorage.getItem("functionalRoleId"))) && validateSidebar(userPermissions, submenu.refName,deploymentConfiguration.Deployment)) {
						matchingMenu.push(<NavLink key={matchingMenu.length} onClick={() => {setOpenMenu(openmenu === val.id ? 0 : val.id);setSearch(""); toggleNav();}} className="search-result" to={submenu.path}>{submenu.title}</NavLink>)
					}
				}
			} else if (val.title.toLocaleLowerCase().includes(search.toLocaleLowerCase()) && val.roles.includes(parseInt(localStorage.getItem("functionalRoleId"))) && validateSidebar(userPermissions, val.refName)) {
				matchingMenu.push(<NavLink key={matchingMenu.length} onClick={() => { setOpenMenu(0); toggleNav(); setActiveMenu(0);setSearch(""); }} className="search-result" to={val.path}>{val.title}</NavLink>)
			}
		}
		setFilteredMenu(matchingMenu)
	}, [search,subMenu]);

	useEffect(() => {
		updatePath()
	}, [location]);

	useEffect(() => {
		const checkIfClickedOutside = e => {
			if (facilitySelectionFlag && clickIdentifyRef.current && !clickIdentifyRef.current.contains(e.target)) {
				dispatch(setFacilitySelectionFlag(false))
			}
		}
		document.addEventListener("mousedown", checkIfClickedOutside)
		return () => {
			document.removeEventListener("mousedown", checkIfClickedOutside)
		}
	}, [facilitySelectionFlag])

	const changeFacility = (facility) =>{
		localStorage.setItem("facilityId", facility.id);
		localStorage.setItem("siteLogo", facility.siteLogo);
		localStorage.setItem("siteName", facility.siteName);
		window.location.reload();
	}
	const toggleNav = () => {
		setToggleMenu(!toggleMenu);
	}
	let navsAvailable = 0;
	for (let index = 0; index < SidebarData.length; index++) {
		let val = SidebarData[index];
	
		if(!localStorage.getItem("accessToken")){
			navigate("/user/login");
		}
		if (val.subNav) {
			for (let innerIndex = 0; innerIndex < val.subNav.length; innerIndex++) {
				let submenu = val.subNav[innerIndex]
				if (submenu.sidebar && submenu.title.toLocaleLowerCase().includes(search.toLocaleLowerCase()) && submenu.roles.includes(parseInt(localStorage.getItem("functionalRoleId"))) && validateSidebar(userPermissions, submenu.refName)) {
					navsAvailable = 1
				}
			}
		} else if (val.title.toLocaleLowerCase().includes(search.toLocaleLowerCase()) && val.roles.includes(parseInt(localStorage.getItem("functionalRoleId"))) && validateSidebar(userPermissions, val.refName)) {
			navsAvailable = 1
		}
	}

	function SideBarRender(val, index, isSubMenu){
		return <div className='sidebar-menu-container' key={index}>						
				{val.subNav ?
					<>
						<div className={'sidebar-menu-item ' + (isSubMenu ? "sub-nav-menu " : "") } onClick={
							() => {
								if(isSubMenu){
									return setSubMenu(subMenu === val.id ? 0 : val.id)
								}
								setSubMenu(0)
								setOpenMenu(openmenu === val.id ? 0 : val.id);
							}}>
							<div key={index} className={'sidebar-menu-item-link ' + (openmenu === val.id || subMenu === val.id ? "active-menu " : "") + (activeMenu === val.id ? "active " : "") + (isSubMenu ? "sub-nav-menu-ctr" : "") } >
								
								<span>{val.icon}</span>
								<span className='name-arrow-nav-ctr'>
									<span className={'title ' + (isSubMenu ? "submenu-nav-title " : "") } data-testid={val.dataTestId} title={val.title}>
										{val.title}
									</span>
									{openmenu === val.id || subMenu === val.id
										?
										<span><img className='nav-menu-icon' src={DownArrow} alt="arrow icon"/></span>
										:
										<span><img className='nav-menu-icon' src={DownArrow} alt="arrow icon"/></span>
									}
								</span>
								
								
						
								
							</div>
						</div>
						{
							subMenu === val.id && val.subNav.map((submenu, index)=>{
								if(submenu.sidebar && submenu.roles.includes(parseInt(localStorage.getItem("functionalRoleId"))) && validateSidebar(userPermissions, submenu.refName) && submenu.subNav){
									return  SideBarRender(submenu, submenu.id, true)
								}
								if (submenu.sidebar && submenu.roles.includes(parseInt(localStorage.getItem("functionalRoleId"))) && validateSidebar(userPermissions, submenu.refName,deploymentConfiguration.Deployment)) {
									
									return <NavLink key={index} to={submenu.path} onClick={() => {window.location.pathname!==submenu.path && dispatch(setLoading(false));toggleNav()}} className="sidebar-sub-menu-item-link">
										<span className={'submenu-title ' + (isSubMenu ? "submenu-nav-sub-title " : "")} data-testid={submenu.dataTestId} id={submenu.dataTestId} title={submenu.title}>
											{submenu.title} 
										</span>
									</NavLink>
								}
							})
						}
						{openmenu === val.id && val.subNav.map((submenu, index) =>{		
							if(submenu.sidebar && submenu.roles.includes(parseInt(localStorage.getItem("functionalRoleId"))) && validateSidebar(userPermissions, submenu.refName) && submenu.subNav){
								return  SideBarRender(submenu, submenu.id, true)
							}
							if (submenu.sidebar && submenu.roles.includes(parseInt(localStorage.getItem("functionalRoleId"))) && validateSidebar(userPermissions, submenu.refName,deploymentConfiguration.Deployment)) {
								return <NavLink key={index} to={submenu.path} onClick={() => {window.location.pathname!==submenu.path && dispatch(setLoading(false));toggleNav()}} className="sidebar-sub-menu-item-link">
									<span className={'submenu-title ' + (isSubMenu ? "submenu-nav-sub-title " : "")} data-testid={submenu.dataTestId} id={submenu.dataTestId} title={submenu.title}>
										{submenu.title} 
									</span>
								</NavLink>
							}
						})}
					</>
					:
					<>
						<div className='sidebar-menu-item' data-testid="side-bar-menu">
							<NavLink key={index} to={val.path} className="sidebar-menu-item-link" onClick={() => {window.location.pathname!==val.path && dispatch(setLoading(false));setOpenMenu(0); toggleNav(); setActiveMenu(0) }}>
								{val.icon}
								<span className="title"  data-testid={val.dataTestId} id={val.dataTestId} title={val.title}>
									{val.title}
								</span>
							</NavLink>
						</div>
					</>
				}
			</div>
	}

	return(
		<div className={toggleMenu ? "nav-bar-ctr shows" : "nav-bar-ctr hides"} data-testid="navbar-container">
			<div className={toggleMenu ? "side-navbar shows" :"side-navbar hides"}>
				<div className="menu-list" id="menu-list"  onClick={()=>toggleNav()} >
					<BiMenu style={{ color: '#fff', width: '15%', height: '9vh', cursor: 'pointer' }} />
				</div> 
				<div className={toggleMenu?"nav-details show":"nav-details hide"}>    
					<div className="logo-details">
						<img 
							data-testid="logo"
							src={logo} 
							onError={(e) => { e.target.onerror = null; e.target.src = SSlogo }}
							alt="logo"
						/>
					</div>
					<div className="login-details">
						<p data-testid="user-logged">Logged in as {firstName+" "+lastName} </p>
						{parseInt(localStorage.getItem("functionalRoleId")) > 2 ?
							<p data-testid="school-logged" className='nav-bar-facility-name'> Editing {logged_school}
								{parseInt(localStorage.getItem("functionalRoleId")) < 5?
									<>
										{facilities.length > 1 ?
											<MdSettingsSuggest onClick={() => dispatch(setFacilitySelectionFlag(!facilitySelectionFlag))} style={{ color: '#fff', width: 'auto', height: '25px', cursor: 'pointer' }} />
										: null}
									</>
								:""}
							</p>
						:""}
						{facilitySelectionFlag ?
							<div className="facility-selection-popup-container" ref={clickIdentifyRef} >
								<div className="facility-selection-popup">
									{facilities.map((facility, index) => {
										return <div className={"facility-selection-popup-item " + (localStorage.getItem("facilityId") == facility.id ? "selected" :"" ) } onClick={()=> changeFacility(facility)} key={index}>
											<img className="facility-selection-popup-logo" src={facility.siteLogo} alt="site logo"/>
											{facility.siteName}
										</div>
									})}
								</div>
							</div>
						: null}	
					</div>
						
					<div className="search-box">
						<input type='text' value={search} placeholder='Search...' id='search-text-input' autoComplete={"off"} onChange={(event) => {document.querySelector('#nav-link-ctr').scrollTo({top: 0,left: 0,behavior: "smooth"});setSearch(event.target.value)}} data-testid="navbar-search"/>
					</div>	
					<div className={search ? "nav-links no-scroll" : "nav-links"} id="nav-link-ctr">
						{SidebarData.map((val, index)=>{
							if (val.roles.includes(parseInt(localStorage.getItem("functionalRoleId"))) && validateSidebar(userPermissions, val.refName) ){
								return SideBarRender(val, index)
							}
						})}
						{!navsAvailable  ?
							<div className='no-menu-container'>
								<div className='no-menu-label' data-testid="no-menu-available">No Menu Available</div>
								<button className='no-menu-button' onClick={()=> window.location.reload()} data-testid="refresh-btn">Refresh</button>
							</div>
						:null}
					</div>
					{search ?
							<div className='search-result-container'>
								<div className='search-result-title'>
									<span data-testid="best-match">Best Matches</span>
									<span className='pointer' onClick={()=> setSearch("")} data-testid="clear-option">Clear</span>
								</div>
								<div className='search-result-list' data-testid="search-result">
									{filteredMenu.length > 0 
										? 
											filteredMenu.map((val, index) => {
												return val
											})
										:
											<div className='no-menu-text' data-testid="no-matching-content">No matching menu</div>
									}
								</div>
							</div>
						:null}
				</div>
			</div>
		</div>
	);
}
export default Nav;
