import { useState,useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Table from '../../../common/component/Table';
import BreadCrumbIcon from '../../../assets/images/breadcrum.png';
import BreadCrumb from '../../../common/component/BreadCrumb';
import * as API from '../../../common/api/index.js';
import URL from "../../../common/api/constantURL";
import { setUser,setSnackData, setLoading } from '../../../redux/action/userAction';
import '../../Style/style.css';
import ViewClass from './ViewClass';
import responseMessages from "../../../common/variables/responseMessages.js";
import './Classes.css'

function Classes(){
	const dispatch = useDispatch();
	const [viewStudentsListFlag, setViewStudentsListFlag] = useState(1);
	const [propData, setPropData] = useState("");
	const [schoolName, setSchoolName] = useState("");
	const [search, setSearch] = useState("");
	const [schools, setSchools] = useState([]);
	const [userIdToNameMap,setUserIdToNameMap] = useState({});
	const [classesData, setClassesData] = useState([]);

	const BreadCrumbArr = [
		{link:"/home/dashboard",display:"Dashboard",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon' />,type:'img'},
		{link:"/home/users",display:"Users and Groups",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon' />,type:'img'},
		{link:"/home/classes",display:"Classes",type:"react"},
	];

	useEffect(() => {
		fetchClasses()
	},[])

	const showClassStudents =(data)=>{
		setViewStudentsListFlag(2);
		data.studentsList = getUserName(data.userIds);
		data.advisorName = getUserName(data.advisorIds);
		setPropData(data);
	}

	let tableHeaders = [
		{ id: 'name', label: 'Class Name', type :"linkclick", width: 100, responsive: true, sortable: true, dataTestid: "class-name" , clickFunc: showClassStudents},
		{ id: 'schoolName', label: "School Name", width: 100, responsive: true, sortable: true, dataTestid: "school-name"},
		{ id: 'advisorName', label: "Teacher Name", width:100, dataTestid: "advisor-name",sortable: true , type: 'multiple_data', responsive: true},
    	{ id: 'studentCount', label: "Student Count", width:100, sortable : true, justifyContent:"center", dataTestid: "students-count"},
		{ id: 'view',dataTestid:"class-view-field", label: 'View', type: 'linkclick', color:"#767b80",path: '#', clickFunc: showClassStudents,  fontSize:16,justifyContent:"center" },
	]

	const fetchClasses = async () => {
		let url = URL.classes + "?facilityId=" + localStorage.getItem("facilityId");
		dispatch(setLoading(true));
		let response = await API.getAPI(url);
		dispatch(setLoading(false));
		if (response.fetchStatus === "success") {
			let classesList = response.result.result;
			let school = schools;
			if (response.result.status === "success") {
				for (const element of classesList) {
					if (element.schoolName && !school.includes(element.schoolName) ){
						school.push(element.schoolName)
					}
				}
				setSchools(school);
				setClassesData(classesList);
				fetchUsers();
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error",
				};
				dispatch(setSnackData(snackData));
			}
		} else {
			let snackData = {
				showSnack: true,
				snackMessage: responseMessages.apiFailureResponse,
				snackVariant: "error",
			};
			dispatch(setSnackData(snackData));
		}
	}

	const fetchUsers = async () => {
		dispatch(setLoading(true))
		let url = URL.usersByFacility + "?facilityId=" + localStorage.getItem("facilityId");
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if (response.fetchStatus === "success") {
			let userList = response.result.result
			if (response.result.status === "success") {
				dispatch(setUser(userList));
				createUserIdToNameMap(userList)
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		} else {
			let snackData = {
				showSnack: true,
				snackMessage: responseMessages.apiFailureResponse,
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
	}

	const createUserIdToNameMap = (users) => {
		let createUserIdToNameMap = {}
		users.forEach(function (user) {
			createUserIdToNameMap[user.id] = user.firstName + " " + user.lastName
		})
		setUserIdToNameMap(createUserIdToNameMap)
	}

  	const formatTableData = () => {
		let classes = classesData
		let tempData = []
		classes && classes.forEach((classObj) => {
			let tempObj = {}
			tempObj = classObj
			tempObj["view"] = "•••";
			tempObj["studentCount"] = classObj.userIds.length
			tempObj["advisorName"] = classObj.advisorIds ? getUserName(classObj.advisorIds) : '-';
			tempData.push(tempObj)
		})
		return tempData
	}

  	const getUserName = (userIds) => {
		let userName = []
		userIds.forEach(function (userId) {
			userName.push(userIdToNameMap[userId])
		});
		return userName
	}

 	let classData = formatTableData();

	const filter = (values) =>{
		let tempValues = [];
		if (schoolName) {
			values.forEach((value) => {
				if (schoolName === value.schoolName) {
					tempValues.push(value)
				}
			})
		} else {
			tempValues = values
		}

		let updatedValues = []
		if (search && search.length > 0) {
			tempValues.forEach((value) => {
				if (
					(value.name || "").toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
					(value.schoolName || "").toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
					(value.advisorName || []).filter((name) => name.toLocaleLowerCase().includes(search.toLocaleLowerCase())).length > 0
				) {
					updatedValues.push(value)
				}
			})
		} else {
			updatedValues = tempValues
		}

		if (!search && !schoolName) {
			return values
		}
		return updatedValues
	}

	return (
		<div className="group-page" id="wrapper">
			{viewStudentsListFlag === 1 ?
				<div data-testid="group-managemnt-ctr-testid">
					<div className=''>
						<BreadCrumb crumbs={BreadCrumbArr} dataTestid="view-class-breadcrum" ></BreadCrumb>
						<div className="list-page-title-div">
							<p data-testid="filter-by-school" className='list-page-title'> Filter by School</p>
						</div>

						<div className="columns group-filters-ctr classes-filter-ctr">
							<div className="col col-12 groups-select-filter-container">
								<select className='groups-select-container' value={schoolName} onChange={
									(e) => {
										setSchoolName(e.target.value)
									}}
									data-testid="school-select-container"
									id="school-select-container"
								>
									<option data-testid="school-select-option" value=''>All</option>
									{schools.map((school, index) =>
										<option data-testid="school-select-option" key={index} id={"select-school-" + index} value={school}>{school}</option>
									)}
								</select>
							</div>
							<div className='classes-search-wrap'>
								<div className="col col-33 margin-left-1-percent search">
									<input type="text" value={search} className="group-search" placeholder="Search" id="group-search-box" data-testid="class-search-box" onChange={(e) => setSearch(e.target.value)}></input>
								</div>
								<div style={{ display: "flex" }} className="reclear group-reset-btn">
									<div data-testid="users-reset-btn" id="users-reset-btn" className='pointer' onClick={() => { setSearch('');setSchoolName("") }} >Reset</div>
								</div>
							</div>
						</div>
					</div>

					<div className="bottom-content ">
						<div className="col col-100">
							<Table
								headers={tableHeaders}
								tableTitle={"Class Details - Total Classes"}
								data={filter(classData)}
								defaultOrderBy={"name"}
								defaultSortOrder={"asc"}
							/>
						</div>
					</div>
				</div>
				:
				<ViewClass
					data={propData}
					onClose={() => { setViewStudentsListFlag(1) }}
				/>
			}
		</div>
	)
}
export default Classes;
